// src/ImageSlider.js
import React, { useEffect, useState } from 'react';
import './ImageSlider.css'; // Import the CSS file for styling
import Imageone from './assets/packaging/fripic1.jpg';
import Imagetwo from './assets/packaging/strich.jpg';
import Imagethree from './assets/packaging/pair_tshirt.jpg';
import Imagefour from './assets/packaging/print.jpg';
import Imagefive from './assets/packaging/box.jpg';
const images = [
    Imageone,
    Imagetwo,
    Imagethree,
    Imagefour,
    Imagefive,
];

const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);

    return (
        <div className="slider-container">
            <div
                className="slider-wrapper"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((src, index) => (
                    <div className="slide" key={index}>
                        <img src={src} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;

import logo from './logo.svg';
import './App.css';
import './wp-content/plugins/jeg-elementor-kit/assets/css/elements/main61da.css?ver=2.6.2';
import './wp-content/plugins/allow-webp-image/public/css/allow-webp-image-publicf269.css';
import './wp-includes/css/dist/block-library/style.minaec2.css';
import './wp-content/plugins/allow-webp-image/public/css/allow-webp-image-publicf269.css?ver=1.0.1'
  import './wp-content/plugins/template-kit-export/public/assets/css/template-kit-export-public.min365c.css?ver=1.0.21'
    import './wp-content/plugins/header-footer-elementor/assets/css/header-footer-elementor1f62.css?ver=1.6.13'
 import './wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min192d.css?ver=5.23.0'
   import './wp-content/plugins/elementor/assets/css/frontend.min8864.css?ver=3.17.3" media="all'
   import './wp-content/plugins/elementor/assets/lib/swiper/css/swiper.min48f5.css?ver=5.3.6'
    import './wp-content/uploads/sites/122/elementor/css/post-4123f.css?ver=1700559820'
    import './wp-content/uploads/sites/122/elementor/css/globalf7d8.css?ver=1700559821' 
    import './wp-content/uploads/sites/122/elementor/css/post-178f84.css?ver=1700560113' 
    import './wp-content/plugins/header-footer-elementor/inc/widgets-css/frontend1f62.css?ver=1.6.13'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/all.min8864.css?ver=3.17.3'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/v4-shims.min8864.css?ver=3.17.3'
    import './wp-content/uploads/sites/122/elementor/css/post-327f7d8.css?ver=1700559821'
    import './wp-content/uploads/sites/122/elementor/css/post-328f7d8.css?ver=1700559821'
    import './wp-content/plugins/metform/public/assets/css/text-editor9e95.css?ver=3.8.0'
    import './wp-content/themes/hello-elementor/style.minc141.css?ver=2.6.1'
      import './wp-content/themes/hello-elementor/theme.minc141.css?ver=2.6.1'
    // import 'https://fonts.googleapis.com/css?family=Lato%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CHeebo%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&amp;display=auto&amp;ver=6.4.1';
    import './wp-content/plugins/jeg-elementor-kit/assets/fonts/jkiticon/jkiticon61da.css?ver=2.6.2'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min52d5.css?ver=5.15.3'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min52d5.css?ver=5.15.3'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min52d5.css?ver=5.15.3'
    import './wp-content/plugins/elementor/assets/lib/font-awesome/css/regular.min52d5.css?ver=5.15.3';
    import {logoads } from './assets/Final Logo_Gold.svg';
import Home from './home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Children, useEffect } from 'react';
// import jquerymigratejs from './wp-includes/js/jquery/jquery-migrate.min5589.js?ver=3.4.1';
import About from './about';
import Service from './service';
import Product from './product';
import Topbar from './topbar';
import Contact from './contact';
import Infrastructure from './infrastructure';
import MenProduct from './menproduct';
import Infants from './infants';
import Women from './womenproduct';
import Child from './Child';


function App() {
  
  return (
    <>
    <Router>
    <Routes>
      
      <Route path="/" element={<Home  />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/service" element={<Service />} />
      <Route path="/product" element={<Product />} />
      <Route path="/infra" element={<Infrastructure />} />
      <Route path="/child" element={<Child/>} />
      <Route path="/men" element={<MenProduct />} />
      <Route path="/women" element={<Women />} />
      <Route path="/infants" element={<Infants />} />
     
      {/* Add more routes as needed */}
    </Routes>
  </Router>
  </>
  
  );
}

export default App;
